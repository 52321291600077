
<template>
  <v-row
    justify="end"
    class="mr-4"
  >
    <v-dialog
      v-model="isVisibleDialog"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click="setSourceHub()"
        >
          <v-icon
            color="white"
            class="mr-2"
          >
            mdi-swap-horizontal
          </v-icon>
          {{ $t("generate_transfer") }}
        </v-btn>
      </template>
      <v-form
        ref="form"
        v-model="is_valid"
        lazy-validation
      >
        <v-card>
          <v-card-title class="pb-4">
            <span class="text-h3">{{ $t("transfer") }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <v-container>
              <v-card elevation="5">
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        dense
                        outlined
                        :label="$t('from_hub_*')"
                        v-model="transfer.from_hub_id"
                        :items="
                          listHubs.filter((item) => {
                            return item.id != transfer.to_hub_id;
                          })
                        "
                        item-text="name"
                        item-value="id"
                        @focus="getHubs"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-autocomplete
                        dense
                        outlined
                        :label="$t('to_hub_*')"
                        v-model="transfer.to_hub_id"
                        :items="
                          listHubs.filter((item) => {
                            return item.id != transfer.from_hub_id;
                          })
                        "
                        item-text="name"
                        item-value="id"
                        @focus="getHubs"
                        :rules="[rules.required]"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <small>{{ $t("indicates_required_field") }}</small>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="closeForm()"
            >
              {{ $t("close") }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="saveForm(transfer)"
              :disabled="!is_valid"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  computed: {
    ...mapGetters({
      listHubs: "hubs/activeHubs",
    }),

    isVisibleDialog: {
      get() {
        return this.$store.state.transferProducts.isVisibleDialog;
      },
      set(value) {
        this.$store.commit("transferProducts/IS_VISIBLE_DIALOG", value);
      },
    },

    options: {
      get() {
        return this.$store.state.productAlerts.params;
      },
      set(value) {
        this.$store.commit("productAlerts/SET_PARAMS", value);
      },
    },
  },

  data() {
    return {
      is_valid: true,
      transfer: {},
    };
  },

  methods: {
    closeForm() {
      this.$store.dispatch("transferProducts/closeForm");
      this.transfer = {};
    },

    setSourceHub() {
      if (this.options?.hub_id > 0) {
        this.transfer.from_hub_id = this.options.hub_id;
      }
    },

    async saveForm(transfer) {
      try {
        const createdTransfer = await this.$store.dispatch(
          "transferProducts/createFromExpired",
          transfer
        );
        this.$store.dispatch("alerts/success", this.$t("updated_successfully"));

        this.$router.push({
          name: "transfers.edit.expired",
          params: { id: createdTransfer.id },
        });
        this.closeForm();
      } catch (error) {
        console.log(error);
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    getHubs() {
      this.$store.dispatch("hubs/list");
    },
  },
};
</script>
